/* eslint-disable no-useless-escape */
/* eslint-disable dot-location */
import React from 'react';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner/spinner'
import { Toolbar } from '../../components/toolbar';
import { Table } from '../../components/table';
import {
	Grid,
	Paper,
	Typography,
	Checkbox
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Input, DatePicker } from '../../components/inputs'
import { MasterSelect } from '../../components/select'
import {
	getSOlist,
	getReportCodes,
	directPrinting
} from '../../store/fms_salem/fms_salem.slice';
import moment from 'moment'
import path from 'path-browserify'
import { base64toBlob, downloadBase64File } from '../../helpers/buttons'
import dayjs from 'dayjs'

const SalemSalesInvoice = ({ routes }) => {
	return (
		<View />
	);
}

const View = () => {
	const navigate = useNavigate();
	const { loading } = useSelector(state => state.fms_salem)
	const dispatch = useDispatch();
	const [handleSet, setHandleSet] = React.useState({
		dateFrom: dayjs().add(-15, 'day'),
		dateTo: dayjs().add(15, 'day')
	})
	const [state, setState] = React.useState({
		dateFrom: dayjs().add(-15, 'day'),
		dateTo: dayjs().add(15, 'day')
	})
	const [checkedSO, setCheckedSO] = React.useState({
		checkedSOList: []
	})
	const [viewedSO, setViewSO] = React.useState({
	})

	const fetchData = React.useCallback(({ pageIndex, pageSize, filters }, callBack) => {
		dispatch(getSOlist({
			route: 'sales-invoice/',
			page: pageIndex,
			totalPage: pageSize,
			orderBy: 'createdAt,DESC',
			filters: {
				...filters,
				so_customerId: state?.so_customer?.label,
				so_warehouseId: state?.so_warehouseId?.label,
				so_Status: state?.so_Status?.label,
				dateFrom: state?.dateFrom,
				dateTo: state?.dateTo
			}
		}))
		.unwrap()
		.then(result => {
			setViewSO({
				data: JSON.stringify(result.data)
			});
			callBack(result)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state])

	const printAll = () => {
		dispatch(directPrinting({
			route: 'sales-invoice/downloadAll',
			data: {
				...viewedSO
			}
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				let typeFormat = 'application/pdf';
				const contentBase64 = result.data.contents;
				// file is a File object, this will also take a blob
				const dataUrl = window.URL.createObjectURL(base64toBlob(contentBase64, typeFormat))

				// Open the window
				const pdfWindow = window.open(dataUrl);

				// Call print on it
				pdfWindow.print();
			}
		})
	}

	const printSelected = () => {
		dispatch(directPrinting({
			route: 'sales-invoice/downloadSelected',
			data: {
				...checkedSO.checkedSOList	
			}
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				let typeFormat = 'application/pdf';
				const contentBase64 = result.data.contents;
				// file is a File object, this will also take a blob
				const dataUrl = window.URL.createObjectURL(base64toBlob(contentBase64, typeFormat))

				// Open the window
				const pdfWindow = window.open(dataUrl);

				// Call print on it
				pdfWindow.print();
			}
		})
	}

	const printSIxlsx = () => {
		dispatch(directPrinting({
			route: 'sales-invoice/downloadZIP',
			data: {
				...viewedSO
			}
		}))
		.unwrap()
		.then(result => {
			if (result.status === 200) {
				let typeFormat = 'application/pdf';
				const contentBase64 = result.data.contents;
				// file is a File object, this will also take a blob
				const filename = result.data.filename;
				const ext = path.extname(filename);
				if (ext.toLowerCase() === '.pdf') {
					// file is a File object, this will also take a blob
					const dataUrl = window.URL.createObjectURL(base64toBlob(contentBase64, typeFormat))

					// Open the window
					const pdfWindow = window.open(dataUrl);

					// Call print on it
					pdfWindow.print();
				}
				else {
					typeFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					if(ext.toLowerCase()==='.zip')
						typeFormat = 'application/zip';
					downloadBase64File({
						typeFormat: { typeFormat },
						contentBase64,
						filename
					})
				}
			}
		})
	}

	const handleSelectChange = (e, name) => {
		setHandleSet({
			...handleSet,
			[name]: e
		})
		if ((handleSet?.dateFrom && handleSet?.dateTo)) {
			setState({
				...handleSet,
				...state,
				[name]: e
			})
		}
	}

	const handleChange = (e) => {
		setHandleSet({
			...handleSet,
			[e.target.name]: e.target.value
		})
		if (e.target.value && (handleSet?.dateFrom || handleSet?.dateTo)) {
			setState({
				...handleSet,
				...state,
				[e.target.name]: e.target.value
			})
		}
	}

	const columns = React.useMemo(() => {
		const handleCheck = (e) => {
			let name = e.target.name;
			let value = e.target.value;
			let updatedSOlist = checkedSO?.checkedSOList;
			if (e.target.checked) {
				updatedSOlist.push({
					[name]: value
				});
			} else {
				updatedSOlist.splice(checkedSO.checkedSOList.indexOf(name), 1);
			}
			setCheckedSO({
				...checkedSO,
				[name]: e.target.checked,
				checkedSOList: updatedSOlist,
			});
		};
		return [
			{
				Header: ' ',
				width: 10,
				Cell: props => {
					return (<Checkbox
						onChange={handleCheck}
						inputProps={{ 'aria-label': 'controlled' }}
						checked={checkedSO[props?.row?.original?.conf_slm_so?.id]}
						value={props?.row?.original?.conf_slm_so?.id}
						name={props?.row?.original?.conf_slm_so?.id}
					/>)
				}
			},
			{
				Header: 'SO Order No.',
				accessor: 'so_conf_id',
				Cell: props => {
					const onClick = () => {
						navigate(`/slm/salem/${props.value}`);
					}
					return (<Typography sx={{ color: '#CC6400' }} style={{ cursor: "pointer" }} onClick={onClick} >{props.row.original.docNo}</Typography>)
				}
			},
			{
				Header: 'Warehouse Location',
				accessor: 'warehouseId'
			},
			{
				Header: 'Principal (Customer)',
				accessor: 'customerId'
			},
			{
				Header: 'RDD',
				accessor: 'expectedShipmentTime1',
				width: 140,
				Cell: props => {
					return props.value ? moment(props.value).format('YYYY-MM-DD') : props.value
				}
			},
			{
				Header: 'PO Date',
				accessor: 'soReferenceB'
			},
			{
				Header: 'PO Reference',
				accessor: 'soReferenceA'
			},
			{
				Header: 'Status',
				accessor: 'conf_slm_so.so_Status',
				Cell: props => {
					return props.value ? props.value : 'Closed SO'
				}
			},
			{
				Header: 'SKU Lines',
				accessor: 'conf_slm_so.so_skuLineCount'
			},
		]
	}, [checkedSO, navigate]
	);

	/**Fetching the source code on the report*/
	React.useEffect(() => {
		dispatch(getReportCodes({
			route: 'sales-invoice/',
			report_id: 'SLM-INV-MNGT-01'
		}))
		.unwrap()
		.then(result => {
			/** Get the source code of the report (na string in json format) */
			let report_source_code = result?.data?.data[0]?.report_source_code || 'pamparampampam';

			/** Check if source code (na string) is JSON.parsable */
			if (/^[\],:{}\s]*$/.test(report_source_code.replace(/\\["\\\/bfnrtu]/g, '@').
				replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
				replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
				setState({
					...state,
					source_code: JSON.parse(report_source_code)
				})
			}
			else {
				setState({
					...state,
					source_code: {}
				})
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, ['SLM-INV-MNGT-01'])

	return (
		<Grid container rowSpacing={1}>
			<Spinner loading={loading} />
			<Grid item md={12}>
				<Toolbar
					label='Sales Invoice Management'
					onDownloadPdf={printAll}
					isDownloadPdf={true}
					textDownloadPdf={state?.source_code?.text?.textDownloadPdf || 'Download All DR'}
					onConfirm={printSelected}
					isConfirm={true}
					textConfirm='Download Selected'
					textDownloadExcel='Download with Sales Invoice'
					isDownloadExcel={state?.source_code?.buttons?.isDownloadExcel}
					onDownloadExcel={printSIxlsx}
				/>
			</Grid>
			
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>

					{/**Dynamically Generate Select Components	*/}
					{typeof state?.source_code?.dropdowns === 'object' ?
						state?.source_code?.dropdowns.map((foo, i) => (
							<Grid item md={12} key={i}>
								<MasterSelect
									size={12}
									key={i}
									paddingLeft={1}
									paddingRight={1}
									fullWidth
									label={foo.label}
									placeholder={foo.placeholder}
									name={foo.name}
									route={foo.route}
									type={foo.type}
									module_code='salem reporthub'
									value={handleSet[foo.name]}
									handleChange={handleSelectChange}
								/>
							</Grid>
						)) : null
					}

					{/**Dynamically Generate Text Fields Components	*/}
					{typeof state?.source_code?.textfields === 'object' ?
						state?.source_code?.textfields.map((foo, i) => (
							<Input
								key={i}
								fullWidth
								size={12}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								required={foo.isRequired}
								name={foo.name}
								value={handleSet[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

					{/**Dynamically Generate Dates Components	*/}
					{typeof state?.source_code?.datefields === 'object' ?
						state?.source_code?.datefields.map((foo, i) => (
							<DatePicker
								key={i}
								size={6}
								variant='outlined'
								isLabelVisible={true}
								label={foo.label}
								name={foo.name}
								value={handleSet[foo.name]}
								handleChange={handleChange}
							/>
						)) : null
					}

				</Grid>
			</Grid>
			<Grid item md={12}>
				<Grid container component={Paper} variant='container'>
					<Table
						loading={loading}
						columns={columns}
						fetchData={fetchData}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default SalemSalesInvoice;